import { makeConstant } from '../helper/typescript';

export default makeConstant({
  Badges: (badge: string) => `https://static.flip.com/fg-svgs/badges/${badge}.png`,
  BellEmoji: 'https://static.flip.com/fg-pngs/bell.png',
  BitmojiAvatar: 'https://bitmoji.api.snapchat.com/api/direct/avatar',
  BitmojiSticker: 'https://sdk.bitmoji.com/me/sticker/',
  BitmojiTroubleshoot: 'https://flip.ms/bitmoji-troubleshoot',
  CanvasGuide: 'https://aka.ms/canvassetup',
  CanvasLogo: 'https://static.flip.com/source/canvas/canvas_logo.png',
  ClapperEmoji: 'https://static.flip.com/fg-pngs/clapper-board.png',
  ClappingHandsEmoji: 'https://static.flip.com/fg-pngs/clapping-hands.png',
  CodeOfConduct: 'https://help.flip.com/hc/articles/6479235410327-Flip-Code-of-Conduct',
  CoLeads: 'https://aka.ms/coleads',
  CrownEmoji: 'https://static.flip.com/fg-pngs/crown.png',
  DefaultIntegrationsImage: 'https://assets.flip.com/p/integrations/default.png',
  DeleteAccountInfo: 'https://aka.ms/deleteaccount',
  DeleteEmail: 'mailto:FlipDeprecation@service.microsoft.com?to=&subject=Flip%20account%20deletion&body=Please%20include%20your%20first%20name,%20last%20name,%20and%20email%20address%20associated%20with%20your%20Flip%20account%20below.%0AFirst%20Name:%0ALast%20Name:%0AEmail%20address:%0AYour%20account%20deletion%20will%20occur%20within%2010%20business%20days%20and%20will%20be%20confirmed%20once%20completed.',
  DownloadVideos: 'https://help.flip.com/hc/articles/360045748833-Download-your-videos',
  EligibleFeaturesImage: 'https://static.flip.com/fg-pngs/eligible-features.png',
  EnableFlip:
    'https://help.flip.com/hc/en-us/articles/17005477431959-Enable-Flip-for-use-with-Google-Workspace-for-Education-',
  EyesEmoji: 'https://static.flip.com/fg-pngs/eyes.png',
  FacebookShare: (link: string) => `https://www.facebook.com/sharer.php?u=${link}`,
  FilmFrames: 'https://static.flip.com/fg-pngs/film-frames.png',
  Flashlight: `https://static.flip.com/fg-pngs/flashlight.png`,
  Flip: 'https://flip.com',
  FlipRebrandFeatures:
    'https://info.flip.com/blog/product-updates/flip-rebrand-features.html?ocid=inapp_banner_pangealaunch',
  GiphyUrl: 'https://api.giphy.com/v1/gifs/',
  GoogleClassroomImage: 'https://static.flip.com/admin/share/google-classroom.png',
  GooglePlayStoreToFlip: 'https://play.google.com/store/apps/details?id=com.vidku.app.flipgrid',
  Help: 'https://help.flip.com',
  HelpAllowStudentTopics: 'https://aka.ms/allowstudentopics',
  HelpStudentTopics: 'https://aka.ms/studentopics',
  HistorysMysteriesImage: 'https://static.flip.com/fg-pngs/screenshot-historys-mysteries.png',
  HourGlassEmoji: 'https://static.flip.com/fg-pngs/hourglass-done.png',
  ITunesStoreToFlip: 'https://itunes.apple.com/us/app/flipgrid./id756972930',
  Info: 'https://info.flip.com',
  LeadOnboarding: 'https://static.flip.com/fg-pngs/LeadOnboarding.png',
  LearnMoreAboutDeletion: 'https://help.flip.com/hc/en-us/articles/4406226548375-Update-or-delete-your-account',
  Mixtapes: 'https://aka.ms/flipgridmixtapes',
  MonocleEmoji: 'https://static.flip.com/fg-pngs/face-with-monocle.png',
  NoEntry: 'https://static.flip.com/fg-pngs/no-entry.png',
  Parachute: 'https://static.flip.com/fg-pngs/parachute.png',
  Placard: 'https://static.flip.com/fg-pngs/placard.png',
  PrivacyPolicy: 'https://info.flip.com/about/trust-and-safety/privacy-policy.html',
  QRCodeBanner: 'https://static.flip.com/fg-svgs/flipQrCodeBanner.svg',
  RaisingHandsEmoji: 'https://static.flip.com/fg-pngs/raising-hands.png',
  RobotEmoji: 'https://static.flip.com/fg-pngs/fluent-robot.png',
  SadButRelievedEmoji: 'https://static.flip.com/fg-pngs/sad-but-relieved-face.png',
  SchoologyGuide: 'https://aka.ms/schoologysetup',
  ShareOnRemind: (url: string) => `https://www.remind.com/v1/share?url=${url}&referrer=com.flipgrid.flipgrid`,
  SignUp: (builderCode?: string) => `https://signup.flip.com/from/${builderCode}`,
  SmubbleWaveforms: 'https://static.flip.com/fg-pngs/smubble-waveforms.png',
  SnailEmoji: 'https://static.flip.com/fg-pngs/snail.png',
  SnapchatAuth: 'https://accounts.snapchat.com/accounts/oauth2/auth',
  SnapchatAuthScope: 'https://auth.snapchat.com/oauth2/api/user.bitmoji.avatar',
  StopLight: 'https://static.flip.com/fg-pngs/vertical-traffic-light.png',
  StudentTemplate: 'https://static.flip.com/admin/gridlaunchpad/student_template.zip',
  SupportForm: 'https://support.flip.com/support/create-case',
  TermsOfUse: 'https://info.flip.com/about/trust-and-safety/terms-of-use.html',
  TopicAssetPlaceholder: 'https://static.flip.com/fg-svgs/topic-asset-placeholder.svg',
  TopicEmptyStateExploreDiscover: 'https://static.flip.com/fg-pngs/topicEmptyStateExploreDiscovery.png',
  TopicModerationHelp: 'https://aka.ms/membertopicmoderation',
  TrustAndSafety: 'https://info.flip.com/about/trust-and-safety.html',
  TwitterShare: (message: string) => `https://twitter.com/intent/tweet?text=${message}`,
  VideoPlayback: 'https://aka.ms/videoplayback',
  YouTubePrivacyPolicy: 'https://policies.google.com/privacy',
  YouTubeTermsOfService: 'https://www.youtube.com/t/terms',
  YoutubeEmbed: (youtubeId: string) => `https://www.youtube-nocookie.com/embed/${youtubeId}`,
});
